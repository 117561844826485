import React from "react"
import { useFormContext } from "react-hook-form"
import InputBox from "./InputBox"

const TextInput = props => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const { name, placeholder, label, position } = props
  return (
    <InputBox name={name} label={label} errors={errors} position={position}>
      <input
        id={name}
        type={"text"}
        className="input-field"
        placeholder={placeholder}
        {...props}
        {...register(name, { valueAsNumber: props.type === "number" })}
      />
    </InputBox>
  )
}

export default TextInput
