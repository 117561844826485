import { zodResolver } from "@hookform/resolvers/zod"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import clsx from "clsx"
import ResponseMessage from "./ResponseMessage"
import { scrollToElement } from "../../common/site/utils"

const Form = ({
  defaultValues,
  children,
  onSubmit,
  validationSchema,
  className,
  successMsg,
  isModalshow
}) => {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)

  // init react-hook-form
  const formMethods = useForm({
    defaultValues,
    resolver: zodResolver(validationSchema),
  })

  const {
    handleSubmit,
    watch,
    formState,
    formState: { errors },
    reset,
  } = formMethods

  useEffect(() => {
    // reset form and show success after onSubmit is run
    if (formState.isSubmitSuccessful) {
      reset()
      setShowSuccessMsg(true)
      const element = document.getElementById("form-success-msg")
      if (isModalshow) {
        element.scrollIntoView({ behavior: "smooth", block: "start" })
      } else {
        scrollToElement(element, 200)
      }
      setTimeout(() => setShowSuccessMsg(false), 10000)
    }
  }, [formState, reset])

  const onFormSubmit = async data => {
    await onSubmit(data)
  }

  return (
    <FormProvider {...formMethods}>
      <div id="form-success-msg">
        {showSuccessMsg && (
          <ResponseMessage message={successMsg} type="success" />
        )}
      </div>
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className={clsx("custom-form", className)}
      >
        {children}
      </form>
      {/* uncomment below for form state preview */}
      {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
    </FormProvider>
  )
}

export default Form
