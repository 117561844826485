import { z } from "zod"

const ERROR = {
  name: "Please enter a valid name",
  firstname: "Please enter a valid first name",
  lastname: "Please enter a valid last name",
  email: "Please enter a valid email address",
  telephone: "Please enter a valid phone number",
  message: "Please enter a valid message",
  property_address: "Please enter a valid address",
  date: "Please enter a valid date",
  time: "Please enter a valid time",
}

const SCHEMA = {
  name: z.string({ message: ERROR.name }).min(1, { message: ERROR.name }),
  firstname: z.string({ message: ERROR.firstname }).min(1, { message: ERROR.firstname }),
  lastname: z.string({ message: ERROR.lastname }).min(1, { message: ERROR.lastname }),
  email: z.string().email({ message: ERROR.email }),
  telephone: z
    .number({ message: ERROR.telephone, invalid_type_error: ERROR.telephone })
    .int({ message: ERROR.telephone })
    .min(4, { message: ERROR.telephone }),
  message: z.string({ message: ERROR.message }),
  property_address: z
    .string({ message: ERROR.property_address })
    .min(1, { message: ERROR.property_address }),
  date: z.string({ message: ERROR.date }),
  time: z.string({ message: ERROR.time }),
  attachment: z
    .any()
    .refine(files => files?.length > 0, "Please upload a file.")
    .refine(files => files?.[0]?.size <= 5000000, `Max file size is 5MB.`),
  nationality: z
    .string({ message: ERROR.name })
    .min(1, { message: ERROR.name }),
  // .refine(
  //   files => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
  //   ".jpg, .jpeg, .png and .webp files are accepted."
  // ),
}

export const newsLetterSchema = z.object({
  name: SCHEMA.name,
  email: SCHEMA.email,
})

export const EnquirySchema = z.object({
  firstname: SCHEMA.name,
  lastname: SCHEMA.name,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  message: SCHEMA.message,
})

export const marketInsightsSchema = z.object({
  firstname: SCHEMA.name,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
})

export const makeAnEnquirySchema = z.object({
  firstname: SCHEMA.name,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  message: SCHEMA.message,
})

export const careersSchema = z.object({
  firstname: SCHEMA.firstname,
  lastname: SCHEMA.lastname,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  attachment: SCHEMA.attachment,
  message: SCHEMA.message,
})
export const contactSchema = z.object({
  firstname: SCHEMA.firstname,
  lastname: SCHEMA.lastname,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  message: SCHEMA.message,
})
export const roadshowSchema = z.object({
  firstname: SCHEMA.firstname,
  lastname: SCHEMA.lastname,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  message: SCHEMA.message,
  date: SCHEMA.date,
  time: SCHEMA.time,
})

export const careersBannerSchema = z.object({
  firstname: SCHEMA.firstname,
  lastname: SCHEMA.lastname,
  email: SCHEMA.email,
  attachment:SCHEMA.attachment,
  telephone: SCHEMA.telephone,
  message: SCHEMA.message,
})

export const valuationSchema = z.object({
  firstname: SCHEMA.firstname,
  lastname: SCHEMA.lastname,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  property_address: SCHEMA.property_address,
  message: SCHEMA.message,
})

export const bookaviewschema = z.object({
  firstname: SCHEMA.firstname,
  lastname: SCHEMA.lastname,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  message: SCHEMA.message,
  date: SCHEMA.date,
  time: SCHEMA.time,
})

export const teamSchema = z.object({
  firstname: SCHEMA.firstname,
  lastname: SCHEMA.lastname,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
  message: SCHEMA.message,
  date: SCHEMA.date,
  time: SCHEMA.time,
})

export const guideSchema = z.object({
  firstname: SCHEMA.firstname,
  lastname: SCHEMA.lastname,
  email: SCHEMA.email,
  telephone: SCHEMA.telephone,
})
