const FORM_CONFIG = {
  newsletter: {
    form_name: "Newsletter subscription",
    email_temp_user: "newsletter_user",
    email_temp_admin: "newsletter_admin",
    email_subject_user: "Thank you for subscribing to our newsletter",
    email_subject_admin: "Newsletter subscription",
    event_tracking: "newsletter_subscription",
    page_url: "/",
  },
  make_enquiry: {
    form_name: "Make an Enquiry",
    email_temp_user: "make_enquiry_user",
    email_temp_admin: "make_enquiry_admin",
    email_subject_user: "Thank you for your enquiry",
    email_subject_admin: "From Web: Make an Enquiry",
    event_tracking: "enquiry",
    page_url: "/register-interest/",
  },
  careers: {
    form_name: "Careers",
    email_temp_user: "career_user",
    email_temp_admin: "career_admin",
    email_subject_user: "Looking to work with Allegiance Real Estate!",
    email_subject_admin: "Looking for opportunity at Allegiance",
    email_server_func: "careers",
    event_tracking: "careers",
    page_url: "/careers",
  },
  team: {
    form_name: "Contact Team",
    email_temp_user: "team_user",
    email_temp_admin: "team_admin",
    email_subject_user: "Connect with Allegiance Team ",
    email_subject_admin: "Team Enquiry",
    email_server_func: "team",
    event_tracking: "team",
    page_url: "/about/meet-the-team",
  },
  market_insights: {
    form_name: "Market Insights",
    email_temp_user: "market_insights_user",
    email_temp_admin: "market_insights_admin",
    email_subject_user: "Be Informed with Allegiance",
    email_subject_admin: "Market Insights",
    email_server_func: "market_insights",
    event_tracking: "market_insights",
    page_url: "/",
  },
  contact: {
    form_name: "Contact",
    email_temp_user: "contact_user",
    email_temp_admin: "contact_admin",
    email_subject_user: "Contact Us",
    email_subject_admin: "Contact Us",
    email_server_func: "contact",
    event_tracking: "contact",
    page_url: "/contact",
  },
  stay_updated: {
    form_name: "Stay Updated",
    email_temp_user: "stay_updated_user",
    email_temp_admin: "stay_updated_admin",
    email_subject_user: "Stay Updated",
    email_subject_admin: "Stay Updated",
    email_server_func: "stay_updated",
    event_tracking: "stay_updated",
    page_url: "/stay_updated",
  },
  roadshow: {
    form_name: "Roadshow Enquiry",
    email_temp_user: "roadshow_user",
    email_temp_admin: "roadshow_admin",
    email_subject_user: "Enquiry about upcoming roadshow",
    email_subject_admin: "Enquiry about upcoming roadshow",
    email_server_func: "roadshow",
    event_tracking: "roadshow",
    page_url: "/roadshow",
  },
  detail_Static: {
    form_name: "Detail static form",
    email_temp_user: "detail_static_user",
    email_temp_admin: "detail_static_admin",
    email_subject_user: "Contact Us",
    email_subject_admin: "Contact Us",
    email_server_func: "contact",
    event_tracking: "detail_static_form",
    page_url: "/contact",
  },
  valuation: {
    form_name: "Book a Valuation",
    email_temp_user: "valuation_user",
    email_temp_admin: "valuation_admin",
    email_subject_user: "Book a Valuation",
    email_subject_admin: "Book a Valuation",
    email_server_func: "book_a_valuation",
    event_tracking: "book_a_valuation",
    page_url: "/book_a_valuation",
  },
  viewing: {
    form_name: "Book a Viewing",
    email_temp_user: "book_viewing_user",
    email_temp_admin: "book_viewing_admin",
    email_subject_user: "Book a Viewing",
    email_subject_admin: "Book a Viewing",
    email_server_func: "book_a_viewing",
    event_tracking: "book_a_viewing",
    page_url: "/book_a_viewing",
  },
  guide: {
    form_name: "Guide",
    email_temp_user: "guide_user",
    email_temp_admin: "guide_admin",
    email_subject_user: "Be Informed with Allegiance",
    email_subject_admin: "Guide",
    email_server_func: "guide",
    event_tracking: "guide",
    page_url: "/contact",
  },
  careersBannerSchema: {
    form_name: "Careers",
    email_temp_user: "career_user",
    email_temp_admin: "career_admin",
    email_subject_user: "Looking to work with Allegiance Real Estate!",
    email_subject_admin: "Looking for opportunity at Allegiance",
    email_server_func: "careers",
    event_tracking: "careers",
    page_url: "/careers",
  },
  listYourProperty: {
    form_name: "List your Property",
    email_temp_user: "list_your_property_user",
    email_temp_admin: "list_your_property_admin",
    email_subject_user: "List Your Property with Allegiance",
    email_subject_admin: "List your Properties",
    email_server_func: "list_your_property",
    event_tracking: "list_your_property",
    page_url: "/sell/list-your-property",
  },
}

export const SUCCESS_MSG =
  "Thank you for contacting us. A member of our team will contact you shortly."
export const SUCCESS_MSG_CAREER =
  "Thank you for your interest. We will be in touch with you shortly."

export default FORM_CONFIG
